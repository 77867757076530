import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import "./layout.scss"
import { SocialIcon } from "react-social-icons"

const Footer = ({ siteTitle }) => (
  <footer style={{ zIndex: 1000 }}>
  <div className="footerContact">
    <div>PICARO SAS</div>
    <div>2 rue Campo Formio,75013 Paris</div>
    <div>contact@picaro.video</div>
    {/* <div>+33 6 49 46 31 76</div> */}
    <div className="socialIcons">
      <div className="socialIcon">
        <SocialIcon url="https://www.linkedin.com/company/70965224/" />
      </div>
      <div className="socialIcon">
        <SocialIcon url="https://www.instagram.com/picaro_video" />
      </div>
    </div>
  </div>
</footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer



            {/* <div className="mobile">
              <div className="mobileLogos">
                <a href="tel:+33670948496">
                  <PhoneIcon />
                </a>
                <a href="mailto:contact@picaro.video">
                  <MailIcon />
                </a>
              </div>
            </div> */}